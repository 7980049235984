// Generated by Framer (1b127cb)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["J2wioVUX6"];

const variantClassNames = {J2wioVUX6: "framer-v-k27mfz"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; background?: string; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "J2wioVUX6", background: toaKH4Vnc = "rgb(19, 88, 73)", image: LoGuNMAV5 = {src: new URL("assets/512/D0bNgiZG5u2mjhd2VCTtRXcKjg.jpg", import.meta.url).href, srcSet: `${new URL("assets/512/D0bNgiZG5u2mjhd2VCTtRXcKjg.jpg", import.meta.url).href} 512w, ${new URL("assets/1024/D0bNgiZG5u2mjhd2VCTtRXcKjg.jpg", import.meta.url).href} 1024w, ${new URL("assets/2048/D0bNgiZG5u2mjhd2VCTtRXcKjg.jpg", import.meta.url).href} 2048w, ${new URL("assets/D0bNgiZG5u2mjhd2VCTtRXcKjg.jpg", import.meta.url).href} 3400w`}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "J2wioVUX6", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-YwhYw", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-k27mfz", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"J2wioVUX6"} ref={ref} style={{backgroundColor: toaKH4Vnc, borderBottomLeftRadius: 40, borderBottomRightRadius: 40, borderTopLeftRadius: 40, borderTopRightRadius: 40, boxShadow: "0px 0.6021873017743928px 0.6021873017743928px -0.9166666666666666px rgba(0, 0, 0, 0.09792), 0px 2.288533303243457px 2.288533303243457px -1.8333333333333333px rgba(0, 0, 0, 0.09488), 0px 10px 10px -2.75px rgba(0, 0, 0, 0.081)", ...style}} transition={transition}><motion.div className={"framer-16biqs4"} layoutDependency={layoutDependency} layoutId={"iyp9jWdaP"} transition={transition}><Image background={{alt: "", fit: "fill", sizes: "357px", ...toResponsiveImage(LoGuNMAV5)}} className={"framer-7jl1qg"} layoutDependency={layoutDependency} layoutId={"CUtGnWm32"} style={{borderBottomLeftRadius: 20, borderBottomRightRadius: 20, borderTopLeftRadius: 20, borderTopRightRadius: 20}} transition={transition}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-YwhYw [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-YwhYw .framer-ft8syf { display: block; }", ".framer-YwhYw .framer-k27mfz { height: 507px; overflow: hidden; position: relative; width: 405px; will-change: transform; }", ".framer-YwhYw .framer-16biqs4 { align-content: center; align-items: center; bottom: 24px; display: flex; flex: none; flex-direction: column; flex-wrap: nowrap; gap: 24px; justify-content: flex-start; left: 24px; overflow: visible; padding: 0px 0px 0px 0px; position: absolute; right: 24px; top: 24px; }", ".framer-YwhYw .framer-7jl1qg { flex: 1 0 0px; height: 1px; overflow: visible; position: relative; width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-YwhYw .framer-16biqs4 { gap: 0px; } .framer-YwhYw .framer-16biqs4 > * { margin: 0px; margin-bottom: calc(24px / 2); margin-top: calc(24px / 2); } .framer-YwhYw .framer-16biqs4 > :first-child { margin-top: 0px; } .framer-YwhYw .framer-16biqs4 > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 507
 * @framerIntrinsicWidth 405
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"toaKH4Vnc":"background","LoGuNMAV5":"image"}
 */
const Framerj1EVGr9jK: React.ComponentType<Props> = withCSS(Component, css, "framer-YwhYw") as typeof Component;
export default Framerj1EVGr9jK;

Framerj1EVGr9jK.displayName = "Card Copy";

Framerj1EVGr9jK.defaultProps = {height: 507, width: 405};

addPropertyControls(Framerj1EVGr9jK, {toaKH4Vnc: {defaultValue: "rgb(19, 88, 73)", title: "Background", type: ControlType.Color}, LoGuNMAV5: {__defaultAssetReference: "data:framer/asset-reference,D0bNgiZG5u2mjhd2VCTtRXcKjg.jpg?originalFilename=a+painting+of+a+rhino+in+a+jungle.jpg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(Framerj1EVGr9jK, [])